import React, { useState } from "react";
import Modal from "../modal/Modal";

const TofaButton = ({ option }) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [cost, setCost] = useState(undefined);

  const { label, className } = option;

  const buttonClass = `tofaButton ${className || ""}`;

  return (
    <>
      {cost ? (
        <h3 className="font-normal">
          Amount: <span className="font-bold">{cost}</span>
        </h3>
      ) : (
        <>
          <button onClick={() => setToggleModal(true)} className={buttonClass}>
            {label || "Default Text"}
          </button>
          {toggleModal && (
            <Modal
              option={option}
              toggleModal={toggleModal}
              setToggleModal={setToggleModal}
              setCost={setCost}
            />
          )}
        </>
      )}
    </>
  );
};

export default TofaButton;
