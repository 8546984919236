import React, { useState, useEffect, useContext } from "react";
import "./Catalog.css";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HeroImg from "../../assets/img/hero-img.png";
import { axiosInstance } from "../../components/baseUrl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProductSkeleton from "../../components/SkeletonLoader/ProductSkeleton";
import { useGetAllCategories } from "../../components/hooks/useGetAllCategories";
import { Capitalize } from "../../components/helpers/functionHelpers";
import { convertPrice } from "../../components/helpers/convertPrice";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useEventTag } from "../../components/hooks/useEventTag";
import { GlobalContext } from "../../components/utils/GlobalState";

const Catalog = () => {
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const [searchedProduct, setSearchedProduct] = useState([]);
  const selectedCurrency = cookies.get("currency") || "NGN";
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const [loading, setLoading] = useState(true);
  const { allCategories, cateLoading } = useGetAllCategories();
  const navigate = useNavigate();
  const { handleEventTag } = useEventTag();

  const ref = React.useRef(null);

  const ref1 = React.useRef(null);

  const handleEventAction = (searchValue) => {
    if (user) {
      handleEventTag(`s_${searchValue}`, user.id);
    } else {
      handleEventTag(`s_${searchValue}`);
    }
  };

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const scroll2 = (scrollOffset) => {
    ref1.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const getSearchedProducts = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/product?productStatus=APPROVED&productName=${q}&category=${q}&firstName=${q}`
      );
      setSearchedProduct(data.data.products);
      handleEventAction(q);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleEventTag("e_search");
      console.log(error);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getSearchedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  return (
    <>
      <Header />
      <section id="product-listing">
        <div className="container">
          <div className="d-flex justify-content-end mb-3">
            <i
              className="fas fa-chevron-left me-4  chev-left carousel-nav"
              onClick={() => scroll(-275)}
            ></i>
            <i
              className="fas fa-chevron-right chev-right carousel-nav"
              onClick={() => scroll(275)}
            ></i>
          </div>
          {cateLoading ? (
            <ul className="product-cat-list" ref={ref}>
              {" "}
              <div className="col-lg-4 col-4">
                <p>
                  <Skeleton height={20} />
                </p>
              </div>
              <div className="col-lg-4 col-4">
                <p>
                  <Skeleton height={20} />
                </p>
              </div>
              <div className="col-lg-4 col-4">
                <p>
                  <Skeleton height={20} />
                </p>
              </div>
            </ul>
          ) : (
            <ul className="product-cat-list" ref={ref}>
              <NavLink
                to="/category/all"
                className={({ isActive }) => {
                  return isActive ? "active-product" : "product-cat";
                }}
              >
                <li>{t("All_Categories")}</li>
              </NavLink>
              {allCategories?.map((category) => (
                <NavLink
                  to={`/category/${category.id}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  key={category.id}
                >
                  <li>
                    <span>{category.category}</span>
                  </li>
                </NavLink>
              ))}
            </ul>
          )}
          <div>
            <div className="filter-head">
              {searchedProduct.length > 0 ? (
                <h2>
                  {searchedProduct.length} {t("results_found")}
                </h2>
              ) : (
                <h2>{t("No_product_matched_your_search")}</h2>
              )}
              <div className="mobile-carousel-nav">
                <i
                  className="fas fa-chevron-left me-4   carousel-nav"
                  onClick={() => scroll2(-275)}
                ></i>
                <i
                  className="fas fa-chevron-right carousel-nav"
                  onClick={() => scroll2(275)}
                ></i>
              </div>
            </div>
            {loading ? (
              <ProductSkeleton />
            ) : (
              <div className="product-wrap" ref={ref1}>
                {searchedProduct?.map((product) => (
                  <Link to={`/product-info/${product.id}`} key={product.id}>
                    <div className="product-card">
                      <div className="product-card-img">
                        <img
                          src={product?.productImages[0]?.image}
                          className=""
                          alt="..."
                        />
                      </div>
                      <div className="product-card-info">
                        <h3 className="seller-name">
                          {product && Capitalize(product.createdBy.firstName)}{" "}
                          {product && Capitalize(product.createdBy.LastName)}
                        </h3>
                        <h2
                          className={
                            product.productName &&
                            product.productName.length > 20
                              ? "product-name-long"
                              : "product-name"
                          }
                        >
                          {product && Capitalize(product.productName)}
                        </h2>
                        <p className="product-price">
                          <span className="p-currency">{selectedCurrency}</span>{" "}
                          {product.minPricePerUnit &&
                            convertPrice(
                              product.minPricePerUnit,
                              selectedCurrency
                            )}{" "}
                          <span className="p-unit">
                            /{product?.unitForMinOrder}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Banner Area */}
      <section className="banner-area">
        <div className="container ">
          <div className="banner-wrap ">
            <img src={HeroImg} className="d-block w-100" alt="..." />
          </div>
        </div>
      </section>
      {/* End of Banner Area */}

      <Footer />
    </>
  );
};

export default Catalog;
