import React, { createContext, useState } from "react";

export const AliexpressCartContext = createContext();

const AliexpressCartState = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const value = {
    cart,
    setCart,
  };

  return (
    <AliexpressCartContext.Provider value={value}>
      {children}
    </AliexpressCartContext.Provider>
  );
};

export default AliexpressCartState;
