import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Capitalize, numberWithCommas } from "../helpers/functionHelpers";
import { useGetAliexpressProductsByCategoryName } from "../hooks/useGetAliexpressProductsByCategoryName";
import ProductSkeleton from "../SkeletonLoader/ProductSkeleton";
import "./DollsAndToys.css";

import AliLogo from "../../assets/img/ali-logo.png";

const DollsAndToys = ({
  sectionTitle,
  convertPrice,
  selectedCurrency,
  user,
  category,
}) => {
  const ref = React.useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const { getAliexpressCategories, loadAli, products } =
    useGetAliexpressProductsByCategoryName();

  useEffect(() => {
    getAliexpressCategories(category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loadAli ? (
        <ProductSkeleton />
      ) : (
        <>
          {products?.length > 0 && (
            <div>
              <div className="section-head">
                <h2>{sectionTitle}</h2>
                <div>
                  <i
                    className="fas fa-chevron-left me-4 scroll-icon"
                    onClick={() => scroll(-275)}
                  ></i>
                  <i
                    className="fas fa-chevron-right scroll-icon "
                    onClick={() => scroll(275)}
                  ></i>
                </div>
              </div>

              <div className="main-container">
                <div className="p-container" ref={ref}>
                  {products?.map((product) => (
                    <Link
                      to={`/aliexpress/product-info/${product?.product_id}`}
                      key={product?.product_id}
                    >
                      <div className="product-card">
                        <div className="product-card-img position__relative">
                          <div className="aliexpress-ctn">
                            <img src={AliLogo} alt="aliexpress logo" />
                          </div>
                          <img
                            src={product?.product_main_image_url}
                            className=""
                            alt="..."
                          />
                        </div>
                        <div className="product-card-info">
                          <h3 className="seller-name">
                            {product &&
                              Capitalize(
                                product?.second_level_category_name
                              )}{" "}
                          </h3>
                          {product && product?.product_title.length > 20 ? (
                            <h2 className={"product-name"}>
                              {Capitalize(product?.product_title).slice(0, 20)}
                              ...
                            </h2>
                          ) : (
                            <h2 className={"product-name"}>
                              {Capitalize(product.product_title)}
                            </h2>
                          )}
                          <p className="product-price">
                            <span className="p-currency">
                              {product?.target_original_price_currency}
                            </span>{" "}
                            {product?.target_original_price &&
                              numberWithCommas(
                                product?.target_original_price
                              )}{" "}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DollsAndToys;
