import { useState } from "react";
import toast from "react-hot-toast";

export const useGetReferralLink = () => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = (referralCode) => {
    const textToCopy = `https://marketplase.tradersofafrica.com/registration/${referralCode}`;

    // Create a textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea from the body
    document.body.removeChild(textarea);

    // Set copied state to true
    setCopied(true);

    // Reset copied state after a short delay
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  // const handleShareClick = async () => {
  //   try {
  //     // Check if the Web Share API is supported
  //     if (navigator.share) {
  //       await navigator.share({
  //         title: "Share via",
  //         text: "Your shared text goes here",
  //         url: "https://your-website-url.com",
  //       });
  //     } else {
  //       // Fallback for browsers that do not support Web Share APIs
  //       toast.error("Share is not supported in this browser.", {
  //         duration: 4000,
  //         style: {
  //           background: "#353434",
  //           color: "#fff",
  //         },
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error sharing:", error);
  //   }
  // };
  return {
    handleCopyClick,
    copied,
  };
};
