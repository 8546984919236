export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function separateCountryCode(phoneNumber) {
  // Regular expression to capture the country code with + and the main number
  const regex = /^(\+\d{1,3})?\s*([0-9().\-\s]*)$/;
  const match = phoneNumber.match(regex);

  if (match) {
    let countryCode = match[1];
    const mainNumber = match[2].trim();

    // If no country code is provided and 10 digits are detected, assume +1 (US)
    if (!countryCode) {
      if (mainNumber.replace(/[^0-9]/g, "").length === 10) {
        countryCode = "+1";
      } else {
        countryCode = "";
      }
    }

    return { countryCode, mainNumber };
  } else {
    return { countryCode: "", mainNumber: phoneNumber };
  }
}

export function calculateTwoPercent(number) {
  const result = number * 0.02;
  return Number(result.toFixed(2));
}

export function addTwoPercent(number) {
  const twoPercent = number * 0.02;
  const total = number + twoPercent;
  return Number(total.toFixed(2));
}

export function sumTwoNumbers(a, b) {
  const sum = Number(a) + Number(b);
  return sum.toFixed(2);
}

export const toastOptions = {
  duration: 4000,
  style: {
    background: "#353434",
    color: "#fff",
  },
};
