import React from "react";
import { Link } from "react-router-dom";
import { useGetAllProduct } from "../hooks/useGetAllProduct";
import { Capitalize } from "../helpers/functionHelpers";

const NewlyAddedProducts = ({
  sectionTitle,
  convertPrice,
  selectedCurrency,
}) => {
  const { allProducts } = useGetAllProduct();
  const ref = React.useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div>
      <div className="section-head">
        <h2>{sectionTitle}</h2>
        <div>
          <i
            className="fas fa-chevron-left me-4 scroll-icon"
            onClick={() => scroll(-275)}
          ></i>
          <i
            className="fas fa-chevron-right scroll-icon "
            onClick={() => scroll(275)}
          ></i>
        </div>
      </div>

      <div className="main-container">
        <div className="p-container" ref={ref}>
          {allProducts?.map((product) => (
            <Link to={`/product-info/${product.id}`} key={product.id}>
              <div className="product-card">
                <div className="product-card-img">
                  <img
                    src={product?.productImages[0]?.image}
                    className=""
                    alt="..."
                  />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    {product && Capitalize(product.createdBy.firstName)}{" "}
                    {product && Capitalize(product.createdBy.LastName)}
                  </h3>
                  {product && product.productName.length > 20 ? (
                    <h2 className={"product-name"}>
                      {Capitalize(product.productName).slice(0, 20)}...
                    </h2>
                  ) : (
                    <h2 className={"product-name"}>
                      {Capitalize(product.productName)}
                    </h2>
                  )}
                  <p className="product-price">
                    <span className="p-currency">{selectedCurrency}</span>{" "}
                    {product.minPricePerUnit &&
                      convertPrice(
                        product.minPricePerUnit,
                        selectedCurrency
                      )}{" "}
                    <span className="p-unit">/{product?.unitForMinOrder}</span>
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewlyAddedProducts;
