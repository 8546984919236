import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";
import { useQuery } from "react-query";

const useGetRandomProducts = () => {
  const navigate = useNavigate();

  const onProductErr = (error) => {
    if (error) {
      navigate("/no-connection");
    }
  };

  const getAllProducts = () => {
    return axiosInstance
      .get(`/product?productStatus=APPROVED&random=${true}`)
      .then((res) => res?.data?.data.products);
  };

  const { data: allProducts, isLoading: loading } = useQuery(
    "randomizedProduct",
    getAllProducts,
    {
      onError: onProductErr,
    }
  );
  return {
    loading,
    allProducts,
  };
};

export default useGetRandomProducts;
