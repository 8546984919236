import React from "react";
import { axiosInstance } from "../baseUrl";

export const useSendOrderInvoiceAsEmail = () => {
  const handleSendInvoice = (invoiceDetails) => {
    try {
      // const invoiceDetails = {
      //   userEmail: "olawaleojo7j@gmail.com",
      //   userPhoneNumber: "+2348134734919",
      //   userName: "Ola Joo",
      //   userAddress: "123 Main St, Anytown, USA",
      //   grandTotal: 219.94,
      //   orderDetails: {
      //     orderId: 12345,
      //     products: [
      //       {
      //         name: "Product 1",
      //         image:
      //           "https://res.cloudinary.com/dcnehcocw/image/upload/v1717599462/yxgeuvpmh6z5410dsdma.png",
      //         quantity: 2,
      //         price: 19.99,
      //       },
      //       {
      //         name: "Product 2",
      //         image:
      //           "https://res.cloudinary.com/dcnehcocw/image/upload/v1717599347/yascth3afdshg3xwtons.png",
      //         quantity: 1,
      //         price: 39.99,
      //       },
      //       {
      //         name: "Product 3",
      //         image:
      //           "https://res.cloudinary.com/dcnehcocw/image/upload/v1717599582/ookdqlhrzwqzogewftqj.png",
      //         quantity: 7,
      //         price: 39.99,
      //       },
      //     ],
      //     totalAmount: 109.97,
      //   },

      //   orderDetails2: {
      //     orderId2: 12345,
      //     products2: [
      //       {
      //         name: "Product 1",
      //         image:
      //           "https://res.cloudinary.com/dcnehcocw/image/upload/v1717599462/yxgeuvpmh6z5410dsdma.png",
      //         quantity: 2,
      //         price: 19.99,
      //       },
      //       {
      //         name: "Product 2",
      //         image:
      //           "https://res.cloudinary.com/dcnehcocw/image/upload/v1717599347/yascth3afdshg3xwtons.png",
      //         quantity: 1,
      //         price: 39.99,
      //       },
      //       {
      //         name: "Product 3",
      //         image:
      //           "https://res.cloudinary.com/dcnehcocw/image/upload/v1717599582/ookdqlhrzwqzogewftqj.png",
      //         quantity: 7,
      //         price: 39.99,
      //       },
      //     ],
      //     totalAmount2: 109.97,
      //   },
      // };

      const data = axiosInstance.post(
        `https://d8b6-102-89-41-34.ngrok-free.app/api/v2/ali-express/order/message`,
        invoiceDetails
      );
    } catch (error) {
      console.log("error sending invoice", error);
    }
  };
  return {
    handleSendInvoice,
  };
};
