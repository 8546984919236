export const vehicleTypes = [
  { label: "Cars", value: "Cars" },
  { label: "Trucks", value: "Trucks" },
  { label: "Motorcycles", value: "Motorcycles" },
  { label: "Bicycles", value: "Bicycles" },
  { label: "Vans", value: "Vans" },
  { label: "Buses", value: "Buses" },
  { label: "Flight", value: "Flight" },
];

export const weightOptions = [
  { value: "1", label: "0 - 1kg (Mobile Phone)" },
  { value: "2", label: "1 - 3kg (Laptop or Small Camera)" },
  { value: "6.5", label: "4 - 9kg (Small Suitcase or Tool Box)" },
  { value: "15", label: "10 - 20kg (Large Backpack or Microwave)" },
  { value: "25", label: "20 - 30kg (Medium-sized TV or Sports Equipment)" },
  { value: "50", label: "30 - 50kg (Large TV or Large Appliance)" },
  { value: "100", label: "50 - 100kg (Heavy Furniture)" },
  { value: "250", label: "100 - 250kg (Small Piano or Large Equipment)" },
  {
    value: "500",
    label: "250 - 500kg (Motorcycle or Heavy Machinery Parts)",
  },
  { value: "1000", label: "500 - 1000kg (Small Car)" },
];

export const currencyOptions = [
  {
    value: "NGN",
    label: "NGN",
  },
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "XDR",
    label: "XDR",
  },
  {
    value: "XOF",
    label: "XOF",
  },
  {
    value: "ZAR",
    label: "ZAR",
  },
  {
    value: "GBP",
    label: "GBP",
  },
];

export const formatNumbers = (num) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Helper function to find the greatest common divisor (GCD)
const findGCD = (numbers) => {
  if (numbers.length === 0) {
    return 1; // Return 1 for an empty array, as the GCD is undefined
  }

  return numbers.reduce((a, b) => {
    if (b === 0) {
      return a;
    }
    return findGCD([b, a % b]);
  });
};

const getRatios = (values) => {
  if (values.length < 2) {
    return [];
  }

  const gcd = findGCD(values);
  const ratios = [];

  values.forEach((value) => {
    const ratio = value / gcd;
    ratios.push(ratio);
  });

  return ratios;
};

export const calculateCost = (cost, distances) => {
  const ratios = getRatios(distances);
  const totalRatio = ratios.reduce((acc, ratio) => acc + ratio, 0);
  if (totalRatio === 0) {
    throw new Error("Total ratio cannot be zero.");
  }

  const splitCosts = ratios.map((ratio) => (cost * ratio) / totalRatio);
  return splitCosts;
};
