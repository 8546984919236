import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./Checkout.css";

import { Toaster } from "react-hot-toast";
import cookies from "js-cookie";
import { useJsApiLoader } from "@react-google-maps/api";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import EditShipping from "../../components/modal/EditShipping";
import SelectPaymentModal from "../../components/modal/SelectPaymentModal";
import CartItems from "./CartItems";
import UserDetails from "./UserDetails";
import ShippingDetails from "./ShippingDetails";
import PaymentMethod from "./PaymentMethod";
import CartPaymentSummary from "./CartPaymentSummary";
import AliexpressCartItems from "./AliexpressCartItems";

import { ProtectedRoutes } from "../../components/utils/ProtectedRoutes";
import { useEditUserProfile } from "../../components/hooks/useEditUserProfile";
import { convertPrice } from "../../components/helpers/convertPrice";
import { useUpdateProductQuantityInCart } from "../../components/hooks/useUpdateProductQuantityInCart";
import { useRemoveProductFromCart } from "../../components/hooks/useRemoveProductFromCart";
import { GlobalContext } from "../../components/utils/GlobalState";
import { AliexpressCartContext } from "../../components/utils/AliexpressCartState";
import { useCreateOrder } from "../../components/hooks/useCreateOrder";

const Checkout = () => {
  const qlhApiKey = process.env.REACT_APP_QLH_API_KEY;

  const { cartItems, totalCartQuantity } = useContext(GlobalContext);
  const { cart, setCart } = useContext(AliexpressCartContext);

  const { qtyLoader } = useUpdateProductQuantityInCart();

  const { deleteLoader } = useRemoveProductFromCart();

  const {
    user,
    handleEditProfile,
    updateLoader,
    handleAddressChange,
    handleCountryChange,
    address,
    country,
    setAddress,
    userLoading,
    isChange,
    setIsChange,
  } = useEditUserProfile();

  const {
    handleSubmit,
    loading,
    setSelectedCurrenci,
    paymentTerm,
    setPaymentTerm,
    setUserDetails,
    userDetails,
    currentSelectedCurrency,
    selectedCurrenci,
    grandTotal,
    totalCost,
  } = useCreateOrder();

  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handlePlacesChanged = () => {
    // Access the Autocomplete instance using refs
    const autocomplete = autocompleteRef.current;

    if (autocomplete) {
      const places = autocomplete.getPlaces();
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        setAddress(selectedPlace.formatted_address || "");
      }
    }
  };

  const addToCart = (product) => {
    if (!user) {
      return navigate("/login");
    }
    setCart((prevCart) => {
      const itemInCart = prevCart.find(
        (item) => item.productId === product.productId
      );
      if (itemInCart) {
        return prevCart.map((item) =>
          item.productId === product.productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prevCart, { ...product, quantity: 1 }];
    });
  };

  const removeFromCart = (productId) => {
    if (!user) {
      return navigate("/login");
    }
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.productId === productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  // Ref to hold the Autocomplete instance
  const autocompleteRef = React.useRef(null);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const sellersDetails = cartItems?.cart?.map((obj) => {
    const { product } = obj;
    return {
      pickupName: `${product?.createdBy?.firstName} ${product?.createdBy?.LastName}`,
      pickupPhoneNumber:
        product?.createdBy?.phoneNumber && product?.createdBy?.phoneNumber,
      pickupAddress: product?.createdBy?.address && product?.createdBy?.address,
    };
  });

  //QLH option to request for ride
  const options = {
    label: "Click here",
    redirectBackLink: "https://marketplase.tradersofafrica.com/checkout",
    apiKey: qlhApiKey,
    receiverName: `${user.firstName} ${user.LastName}`,
    deliveryAddress: user?.address && user?.address, // Optional: Include other query parameters if needed
    receiverPhone: user?.phoneNumber && user?.phoneNumber, // Optional: Include other query parameters if needed
    receiverEmail: user?.email && user?.email,
    pickupDetails: sellersDetails,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const handlePaymentTermChange = (e) => {
    setPaymentTerm(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrenci(e.target.value);
    cookies.set("currency", e.target.value);
  };

  return (
    <div className="checkout-ctn">
      <Toaster />
      {userLoading ? (
        <div className={"dim-loader"}></div>
      ) : !isLoaded ? (
        <div className={"dim-loader"}></div>
      ) : qtyLoader ? (
        <div className={"dim-loader"}></div>
      ) : deleteLoader ? (
        <div className={"dim-loader"}></div>
      ) : null}
      <Header />
      <div className="cheeck">
        <div className="checkout-wrap">
          <div className="row mt-5">
            <div className="col-md-8 col-12">
              <div className="row">
                <UserDetails
                  user={user}
                  address={address}
                  handleAddressChange={handleAddressChange}
                  country={country}
                  handleCountryChange={handleCountryChange}
                  handleEditProfile={handleEditProfile}
                  updateLoader={updateLoader}
                  setAddress={setAddress}
                  handlePlacesChanged={handlePlacesChanged}
                  autocompleteRef={autocompleteRef}
                  isChange={isChange}
                  setIsChange={setIsChange}
                />

                {totalCartQuantity > 0 && (
                  <>
                    <ShippingDetails userDetails={userDetails} />
                    <PaymentMethod paymentTerm={paymentTerm} />
                  </>
                )}

                <AliexpressCartItems
                  cart={cart}
                  removeFromCart={removeFromCart}
                  addToCart={addToCart}
                />

                <CartItems
                  cartItems={cartItems}
                  selectedCurrency={currentSelectedCurrency}
                />
              </div>
            </div>

            <CartPaymentSummary
              cart={cart}
              cartItems={cartItems}
              loader={loading}
              handleSubmit={handleSubmit}
              currentSelectedCurrency={currentSelectedCurrency}
              convertPrice={convertPrice}
              options={options}
              paymentTerm={paymentTerm}
              address={address}
              grandTotal={grandTotal}
              totalCost={totalCost}
            />
          </div>
        </div>
      </div>
      <Footer />

      {/* modal for address */}
      {/* modal for shipping */}
      <EditShipping userDetails={userDetails} setUserDetails={setUserDetails} />
      {/* modal for shipping */}
      {/* modal for payment option */}
      <SelectPaymentModal
        paymentTerm={paymentTerm}
        handlePaymentTermChange={handlePaymentTermChange}
        handleCurrencyChange={handleCurrencyChange}
        selectedCurrenci={selectedCurrenci}
      />
      {/* modal for payment option */}
    </div>
  );
};

export default ProtectedRoutes(Checkout, ["BUYER", "SELLER"]);
