import React, { useEffect, useState } from "react";
import "./Modal.css";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import {
  calculateCost,
  currencyOptions,
  formatNumbers,
  vehicleTypes,
  weightOptions,
} from "../data";
import axios from "axios";
import { BiTimeFive } from "react-icons/bi";
import { IoMdBicycle } from "react-icons/io";
import PaymentFeedBack from "./PaymentFeedBack";

const Modal = ({ option, toggleModal, setToggleModal, setCost }) => {
  const [page, setPage] = useState("checkoutForm1");
  const [checkoutData, setCheckoutData] = useState({
    fullName: option.receiverName ?? "",
    address: option.deliveryAddress ?? "",
    phoneNumber: option.receiverPhone ?? "",
    email: option.receiverEmail ?? "",
    notes: "",
    logisticsCompanyID: "",
    cost: undefined,
    vehicleID: "",
  });
  const [requiredFlag, setRequiredFlag] = useState({
    requireFullName: false,
    requireAddress: false,
    requirePhoneNumber: false,
    requireEmail: false,
    requireVehicleType: false,
    requireSelectedWeight: false,
    requireSelectedCurrency: false,
  });
  const [selectedDropdown, setSelectedDropdown] = useState({
    selectedWeight: "",
    vehicleType: "",
  });
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [distanceAPIData, setDistanceAPIData] = useState({
    deliveryCoordinate: "",
    pickupDetails: [],
    totalDistance: 0,
  });
  const [listOfLogisticsCompany, setListOfLogisticsCompany] = useState();
  const [createDeliverySuccessCash, setCreateDeliverySuccessCash] =
    useState(false);

  const BACKEND_URL = "https://backend.quicklogisticshub.com/api";

  const {
    fullName,
    address,
    phoneNumber,
    email,
    notes,
    logisticsCompanyID,
    cost,
    vehicleID,
  } = checkoutData;

  const {
    requireFullName,
    requireAddress,
    requirePhoneNumber,
    requireEmail,
    requireVehicleType,
    requireSelectedWeight,
    requireSelectedCurrency,
  } = requiredFlag;

  const handleInputChange = (property, value) => {
    setCheckoutData((prevData) => ({
      ...prevData,
      [property]: value,
    }));
  };

  const handleSelect = async (loaction) => {
    await geocodeByAddress(loaction);
    setCheckoutData((prevData) => ({
      ...prevData,
      address: loaction,
    }));
  };

  const handleDropdownChange = (event, key) => {
    setSelectedDropdown((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const concatenatedAddresses = option.pickupDetails
    .map((item) => item.pickupAddress)
    .map((addressString, index, array) => {
      if (index !== array.length - 1) {
        return `${addressString} |`;
      }
      return addressString;
    })
    .join(" ");

  const getDistanceAPI = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${BACKEND_URL}/deliveries/distance/?pickupAddress=${concatenatedAddresses}&deliveryAddress=${address}`,
        {
          headers: {
            "x-api-key": option.apiKey,
          },
        }
      );
      // return console.log(response);

      setDistanceAPIData((prevState) => ({
        ...prevState,
        deliveryCoordinate: `${response.data.data.deliveryCoordinate.lat}/${response.data.data.deliveryCoordinate.lng}`,
        pickupDetails: response.data.data.pickupDetails,
        totalDistance: response.data.data.totalDistance,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequiredField = (stateName, name) => {
    setRequiredFlag((prevRequiredFlag) => ({
      ...prevRequiredFlag,
      [stateName]: true,
    }));

    // return toast.error(`${name}`);
    return alert(`${name}`);
  };

  const submitCustomerDetails = async (e) => {
    e.preventDefault();

    if (!fullName) {
      return handleRequiredField("requireFullName", "Fullname is required");
    }

    if (!address) {
      return handleRequiredField("requireAddress", "Address is required");
    }

    if (!phoneNumber) {
      return handleRequiredField(
        "requirePhoneNumber",
        "Phone number is required"
      );
    }

    if (!email) {
      return handleRequiredField("requireEmail", "Email is required");
    }

    if (!selectedDropdown.vehicleType) {
      return handleRequiredField(
        "requireVehicleType",
        "Please select vehicle type"
      );
    }

    if (!selectedDropdown.selectedWeight) {
      return handleRequiredField(
        "requireSelectedWeight",
        "Please select the package weight"
      );
    }

    if (!selectedCurrency) {
      return handleRequiredField(
        "requireSelectedCurrency",
        "Please select the payment currency"
      );
    }

    setBtnIsLoading(true);
    await getDistanceAPI();
    setBtnIsLoading(false);

    setPage("logisticsSelectForm");

    return Promise.resolve();
  };

  const fetchAllLogisticsProvider = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${BACKEND_URL}/deliveries/logistics-companies?packageWeight=${selectedDropdown.selectedWeight}&vehicleType=${selectedDropdown.vehicleType}&distance=${distanceAPIData.totalDistance}&pickupAddress=${concatenatedAddresses}&deliveryAddress=${address}`,
        {
          headers: {
            "x-api-key": option.apiKey,
          },
        }
      );
      setIsLoading(false);

      setListOfLogisticsCompany(response.data.data.vehiclesWithCalculatedCosts);
    } catch (error) {
      console.log(error);
      alert(error.response.data.err.message);
      setPage("checkoutForm1");
    }
  };

  const handleLogisticsServiceSelection = (item) => {
    setCheckoutData((prevData) => ({
      ...prevData,
      logisticsCompanyID: item.logisticsCompanyID,
      cost: item.cost,
      vehicleID: item.vehicleID,
    }));
  };

  const submitLogisticsSelected = async (e) => {
    e.preventDefault();

    if (!vehicleID) {
      // toast.error("Please select a logistics provider");
      alert("Please select a logistics provider");
      return;
    }

    const costs = calculateCost(
      cost,
      distanceAPIData.pickupDetails.map((item) => item.distance)
    );

    const deliveryData = distanceAPIData.pickupDetails.map((data, index) => ({
      receiverPhoneNumber: phoneNumber,
      pickupAddress: data.pickupAddress.toString(),
      deliveryAddress: address,
      cost: Number(costs[index].toFixed(2)),
      packageWeight: selectedDropdown.selectedWeight,
      receiverName: fullName,
      receiverEmail: email,
      logisticsCompanyID,
      vehicleID,
      pickupCoordinates: `${data.pickupCoordinates.lat}/${data.pickupCoordinates.lng}`,
      deliveryCoordinates: distanceAPIData.deliveryCoordinate,
      pickupName: option.pickupDetails[index].pickupName,
      pickupPhoneNumber: option.pickupDetails[index].pickupPhoneNumber,
    }));

    try {
      await axios.post(`${BACKEND_URL}/deliveries`, deliveryData, {
        headers: {
          "x-api-key": option.apiKey,
        },
      });
      setIsLoading(false);

      setCreateDeliverySuccessCash(true);
      setCost(cost);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRequiredFlag({
        requireFullName: false,
        requireAddress: false,
        requirePhoneNumber: false,
        requireEmail: false,
        requireVehicleType: false,
        requireSelectedWeight: false,
        requireSelectedCurrency: false,
      });
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [requiredFlag]);

  useEffect(() => {
    if (page === "logisticsSelectForm") {
      fetchAllLogisticsProvider();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      {!createDeliverySuccessCash ? (
        <div className="app_container ">
          <div className="tofa_modal">
            <div className="modal_container">
              <div className="">
                {page === "checkoutForm1" && (
                  <>
                    <div className="lead">
                      <h3>Checkout</h3>
                    </div>
                    <div className="modal__content">
                      <p>Enter the delivery details</p>
                      <div className="modal__content__wrapper">
                        <p>Pickup details</p>
                        {option.pickupDetails?.map((item, index) => (
                          <div key={index}>
                            <div className="modal_ind__content_wrapper">
                              <p>Name</p>
                              <p>{item.pickupName}</p>
                            </div>
                            <div className="modal_ind__content_wrapper">
                              <p>Address</p>
                              <p>{item.pickupAddress}</p>
                            </div>
                            <div className="modal_ind__content_wrapper">
                              <p>Phone number</p>
                              <p>{item.pickupPhoneNumber}</p>
                            </div>
                            <hr />
                          </div>
                        ))}
                      </div>
                      <div className="inputWrapper">
                        <form onSubmit={submitCustomerDetails}>
                          <div className="content__wrapper">
                            <div className="input__wrapper">
                              <label htmlFor="fullName">Full Name</label>
                              <input
                                id="fullName"
                                type="text"
                                value={fullName}
                                onChange={(event) =>
                                  handleInputChange(
                                    "fullName",
                                    event.target.value
                                  )
                                }
                                placeholder="Add delivery details"
                                className={`${
                                  requireFullName ? `error` : `normal`
                                } `}
                                required={requireFullName}
                                style={{
                                  borderColor: requireFullName
                                    ? "red"
                                    : "rgba(203, 210, 224, 1)",
                                }}
                              />
                            </div>

                            <PlacesAutocomplete
                              value={address}
                              onChange={(value) =>
                                handleInputChange("address", value)
                              }
                              onSelect={handleSelect}
                            >
                              {(item) => (
                                <div className="placesAutocomplete">
                                  <label htmlFor="address">Address</label>
                                  <input
                                    {...item.getInputProps({
                                      placeholder: "Enter Location",
                                      className: "location-search-input",
                                      // className: `location-search-input w-[300px] md:w-[400px] py-[8px] md:py-[11px] px-[12px] text-sm  rounded-[6px] outline-none ${
                                      //   requireAddress
                                      //     ? `border-red-500 border-[2px]`
                                      //     : `border-border_color border-[1px]`
                                      // }`,

                                      id: "address",
                                    })}
                                    style={{
                                      borderColor: requireAddress
                                        ? "red"
                                        : "rgba(203, 210, 224, 1)",
                                    }}
                                  />
                                  <div className="autocomplete-dropdown-container dropDown">
                                    {item.loading && <div>Loading...</div>}
                                    {item.suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active w-[300px] md:w-[400px]"
                                        : "suggestion-item w-[300px] md:w-[400px]";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          {...item.getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                            <div className="input__wrapper">
                              <label htmlFor="fullName">
                                Enter your phone number
                              </label>
                              <input
                                id="phoneNumber"
                                type="text"
                                value={phoneNumber}
                                onChange={(event) =>
                                  handleInputChange(
                                    "phoneNumber",
                                    event.target.value
                                  )
                                }
                                className={`${
                                  requirePhoneNumber ? `error` : `normal`
                                } `}
                                placeholder="Enter your phone number"
                                required={requirePhoneNumber}
                              />
                            </div>

                            <div className="input__wrapper">
                              <label htmlFor="fullName">Email address</label>
                              <input
                                id="email"
                                type="text"
                                value={email}
                                onChange={(event) =>
                                  handleInputChange("email", event.target.value)
                                }
                                className={`${
                                  requireEmail ? `error` : `normal`
                                } `}
                                placeholder="Enter your email"
                                required={requireEmail}
                              />
                            </div>

                            <div className="input__wrapper">
                              <label htmlFor="vehicleType">
                                Select Vehicle Type
                              </label>
                              <select
                                id="vehicleType"
                                value={selectedDropdown.vehicleType}
                                onChange={(e) =>
                                  handleDropdownChange(e, "vehicleType")
                                }
                                className={`${
                                  requireVehicleType ? `error` : `normal`
                                }`}
                                required={requireVehicleType}
                              >
                                <option value="">Select an option</option>
                                {vehicleTypes.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="input__wrapper">
                              <label htmlFor="selectedWeight" className="">
                                Select Weight:
                              </label>
                              <select
                                id="selectedWeight"
                                value={selectedDropdown.selectedWeight}
                                onChange={(e) =>
                                  handleDropdownChange(e, "selectedWeight")
                                }
                                className={`${
                                  requireSelectedWeight ? `error` : `normal`
                                }`}
                                required={requireSelectedWeight}
                              >
                                <option value="">Select an option</option>
                                {weightOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                    className=""
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="input__wrapper">
                              <label htmlFor="currency">
                                Select Payment Currency:
                              </label>
                              <select
                                id="currency"
                                value={selectedCurrency}
                                onChange={handleCurrencyChange}
                                className={`${
                                  requireSelectedCurrency ? `error` : `normal`
                                }`}
                                required={requireSelectedCurrency}
                              >
                                <option value="">Select an option</option>
                                {currencyOptions.map((currency) => (
                                  <option
                                    key={currency.value}
                                    value={currency.value}
                                  >
                                    {currency.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div
                              className="input__wrapper {
"
                            >
                              <label htmlFor="notes">Notes (Optional)</label>
                              <textarea
                                id="notes"
                                value={notes}
                                onChange={(event) =>
                                  handleInputChange("notes", event.target.value)
                                }
                                className=""
                                placeholder="Add notes"
                              />
                            </div>
                            <div className="btn__wrapper">
                              <button
                                onClick={() => setToggleModal(!toggleModal)}
                                type="button"
                              >
                                Cancel
                              </button>
                              {btnIsLoading ? (
                                <button type="submit" className="">
                                  Loading...
                                </button>
                              ) : (
                                <button type="submit" className="">
                                  Continue & Pay
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                )}
                {page === "logisticsSelectForm" && (
                  <>
                    {isLoading ? (
                      <div className="loading__wrapper">
                        <div>
                          <h1>Loading...</h1>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {listOfLogisticsCompany?.length < 1 ? (
                          <div className="listOfLogisticsCompany__empty">
                            <h3>No Logistics Company Available</h3>
                          </div>
                        ) : (
                          <div className="listOfLogisticsCompany__wrapper">
                            <p>Select the logistics services of your choice</p>
                            <div className="listOfLogisticsCompany__wrapper__content__wrapper">
                              {listOfLogisticsCompany?.map((item) => (
                                <div
                                  className={`selectVehicle__wrapper ${
                                    vehicleID === item.vehicleID ? "active" : ""
                                  }`}
                                  key={item.vehicleID}
                                  onClick={() =>
                                    handleLogisticsServiceSelection(item)
                                  }
                                >
                                  <div className="imgText_wrapper">
                                    <img src={item.logo} alt="logo" />
                                    <p>{item.name}</p>
                                  </div>
                                  <div className="middle">
                                    <div>
                                      <BiTimeFive />
                                      <p>
                                        {" "}
                                        Pickup within {item.pickupDuration}day
                                      </p>
                                    </div>
                                    <div>
                                      <IoMdBicycle />
                                      <p>
                                        Delivery between {item.deliveryDuration}
                                        days
                                      </p>
                                    </div>
                                  </div>
                                  <h3>₦{formatNumbers(item.cost)}</h3>
                                </div>
                              ))}
                            </div>

                            <div className="listOfLogisticsCompany__btn__wrapper">
                              <button
                                onClick={() => setPage("checkoutForm1")}
                                type="button"
                              >
                                Back
                              </button>
                              <button
                                type="submit"
                                onClick={submitLogisticsSelected}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PaymentFeedBack
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
        />
      )}
    </>
  );
};

export default Modal;
