import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BuyersSidebar from "../BuyersComponent/BuyerSideBar";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { GlobalContext } from "../../../../components/utils/GlobalState";
import { useGetBuyerOrders } from "../../../../components/hooks/useGetBuyerOrders";
import { useTranslation } from "react-i18next";

import Pagination from "react-responsive-pagination";
import TableSkeleton from "../../../../components/SkeletonLoader/TableSkeleton";
import CardOverviewLoader from "../../../../components/SkeletonLoader/CardOverviewLoader";
import BuyersHeader from "../BuyersComponent/BuyersHeader";
import {
  Capitalize,
  numberWithCommas,
} from "../../../../components/helpers/functionHelpers";
import cookies from "js-cookie";
import { convertPrice } from "../../../../components/helpers/convertPrice";
import { convertDateFormat } from "../../../../components/helpers/convertDate";

const BuyersOrders = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const selectedCurrency = cookies.get("currency") || "NGN";

  const { user, userLoading } = useContext(GlobalContext);
  const {
    handlePageChange,
    summary,
    orderLoading,
    totalPages,
    loading,
    allUserOrder,
    currentPage,
    handleSubmit,
    search,
    handleChange,
  } = useGetBuyerOrders();

  const navigate = useNavigate();

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div>
      <div className="grid-container">
        <BuyersHeader
          title="My Orders"
          handleClick={handleClick}
          user={user}
          userLoading={userLoading}
          Capitalize={Capitalize}
        />

        <BuyersSidebar isActive={isActive} handleClick={handleClick} />

        <main className="main">
          <h1 className="section-title">{t("Activity_Summary")}</h1>
          {orderLoading ? (
            <CardOverviewLoader />
          ) : (
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Total_Orders")}</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_pending_orders &&
                          numberWithCommas(
                            summary.total_pending_orders +
                              summary.total_shipped_orders +
                              summary.total_delivered_orders +
                              summary.total_confirmed_orders
                          )}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Ongoing_Orders")}</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_pending_orders &&
                          numberWithCommas(
                            summary.total_pending_orders +
                              summary.total_shipped_orders +
                              summary.total_confirmed_orders
                          )}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Completed_Orders")}</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_delivered_orders &&
                          numberWithCommas(summary.total_delivered_orders)}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section-title-ctn">
            <h1 className="section-title">{t("All_Orders")}</h1>
            <div className="header__search">
              <form onSubmit={handleSubmit}>
                <div className="custom__search">
                  <button type="submit">
                    {" "}
                    <i className="fas fa-search"></i>
                  </button>
                  <input
                    type="text"
                    className="form-control custom-style"
                    id=""
                    placeholder="Search orders, order status..."
                    value={search}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
          </div>

          {loading ? (
            <TableSkeleton />
          ) : (
            <>
              {allUserOrder && allUserOrder.length > 0 ? (
                <div className="main-overview">
                  <div className="overview-card no-padding">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">{t("Date")}</th>
                            <th scope="col">{t("Order_No")}</th>
                            <th scope="col">{t("Product")}</th>
                            <th scope="col">{t("Cost")}</th>
                            <th scope="col">{t("Status")}</th>
                            <th scope="col">{t("Action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allUserOrder &&
                            allUserOrder.map((orders, index) => (
                              <tr key={index}>
                                <td>{convertDateFormat(orders.createdAt)}</td>
                                <td>{orders.orderNumber}</td>
                                <td>
                                  <p>
                                    <Link to={`/buyer/order-info/${orders.id}`}>
                                      {orders.productName
                                        ? Capitalize(orders.productName)
                                        : orders.product
                                        ? Capitalize(orders.product.productName)
                                        : " "}
                                    </Link>
                                  </p>
                                </td>
                                <td>
                                  {selectedCurrency}{" "}
                                  {orders &&
                                    convertPrice(orders.cost, selectedCurrency)}
                                </td>
                                <td>
                                  {orders.status === "PENDING" && (
                                    <div className="text-warning ">{t("Pending")}</div>
                                  )}
                                  {orders.status === "PROCESSING" && (
                                    <div className="text-primary ">
                                      {t("Processing")}
                                    </div>
                                  )}
                                  {orders.status === "SHIPPED" && (
                                    <div className="text-info">{t("Shipped")}</div>
                                  )}
                                  {orders.status === "DELIVERED" && (
                                    <div className="text-success">
                                      {t("Delivered")}
                                    </div>
                                  )}
                                  {orders.status === "RECEIVED" && (
                                    <div className="text-secondary">
                                      {t("Received")}
                                    </div>
                                  )}
                                  {orders.status === "NOT RECEIVED" && (
                                    <div className="text-secondary">
                                      {t("Not_Received")}
                                    </div>
                                  )}
                                  {orders.status === "CANCELLED" && (
                                    <div className="text-danger">{t("Cancelled")}</div>
                                  )}
                                </td>
                                <td>
                                  <Link to={`/buyer/order-info/${orders.id}`}>
                                    {t("View")}
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {search ? (
                    <div className="empty-state">
                      <h4>{t("No_results_found")}</h4>
                      <p>
                      {t("No_order_matched")}
                      </p>
                    </div>
                  ) : (
                    <div className="empty-state">
                      <h3>{t("There_are_no_orders")}</h3>
                      <p>
                      {t("Start_order_in_the")}{" "}
                        <Link to="/message-center">{t("Message_Center.")}</Link> 
                        {t("All_your_order")}
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <Pagination
            current={currentPage}
            total={totalPages}
            onPageChange={handlePageChange}
            extraClassName="justify-content-start"
          />
        </main>
      </div>
    </div>
  );
};

export default ProtectedRoutes(BuyersOrders, ["BUYER", "SELLER"]);
