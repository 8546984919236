import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useFlutterwave } from "flutterwave-react-v3";
import toast from "react-hot-toast";
import cookies from "js-cookie";

import { axiosInstance } from "../baseUrl";
import { useEventTag } from "./useEventTag";
import { GlobalContext } from "../utils/GlobalState";
import { AliexpressCartContext } from "../utils/AliexpressCartState";
import {
  addTwoPercent,
  numberWithCommas,
  separateCountryCode,
  sumTwoNumbers,
  toastOptions,
} from "../helpers/functionHelpers";
import { useDeleteBulkProductCart } from "./useDeleteBulkProductCart";
import { useUpdateOrderStatus } from "./useUpdateOrderStatus";
import { convertPrice } from "../helpers/convertPrice";
import { useSendOrderInvoiceAsEmail } from "./useSendOrderInvoiceAsEmail";

export const useCreateOrder = () => {
  const title = process.env.REACT_APP_COMPANY_TITLE;
  const selectedCurrency = cookies.get("currency") || "NGN";

  const [searchParams] = useSearchParams();
  const logisticsStatus = searchParams.get("status") || "";

  const { user, cartItems, totalCartQuantity } = useContext(GlobalContext);
  const { cart } = useContext(AliexpressCartContext);
  const { handleSendInvoice } = useSendOrderInvoiceAsEmail();

  const { handleEventTag } = useEventTag();
  const { handleDelete } = useDeleteBulkProductCart();
  const { updateOrderStatus } = useUpdateOrderStatus();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedCurrenci, setSelectedCurrenci] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [userDetails, setUserDetails] = useState({
    port: "",
    incoterm: "",
    shippingType: "",
  });

  const currentSelectedCurrency = selectedCurrenci || selectedCurrency;

  const errors = [
    { condition: !user?.address, message: "Please enter delivery address." },
    {
      condition:
        !userDetails.shippingType || userDetails.shippingType === "Select",
      message: "Please select mode of delivery.",
    },
    {
      condition: !userDetails.incoterm || userDetails.incoterm === "Select",
      message: "Please select incoterm.",
    },
    {
      condition: userDetails.incoterm !== "LOCAL_DELIVERY" && !userDetails.port,
      message: "Please enter port.",
    },
    {
      condition: !paymentTerm || paymentTerm === "Select payment method",
      message: "Please select payment method.",
    },
  ];

  const totalCost = cart.reduce((sum, item) => {
    const cost = parseFloat(item.cost);
    const quantity = item.quantity;
    const total = sum + cost * quantity;
    return total;
  }, 0);

  function removeCommasAndConvert() {
    const tofaTotalCost = convertPrice(
      cartItems?.cartPrice,
      currentSelectedCurrency
    );
    let noCommas = tofaTotalCost.replace(/,/g, "");
    return Number(noCommas);
  }

  const grandTotal = () => {
    if (totalCartQuantity > 0 && cart.length > 0) {
      return sumTwoNumbers(removeCommasAndConvert(), addTwoPercent(totalCost));
    } else if (totalCartQuantity > 0 && cart.length < 1) {
      return removeCommasAndConvert();
    } else if (totalCartQuantity < 1 && cart.length > 0) {
      return addTwoPercent(totalCost);
    } else return "0.00";
  };

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_API_KEY,
    tx_ref: Date.now(),
    amount: grandTotal(),
    currency: selectedCurrency === "NGN" ? "NGN" : "USD",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user.email,
      phone_number: user.phoneNumber,
      name: user.firstName + " " + user.LastName,
    },
    customizations: {
      title: title,
      description: "Payment for items in cart",
      logo: "https://res.cloudinary.com/dcnehcocw/image/upload/v1714313366/Screenshot_2024-04-28_at_3.08.04_PM_dk2ifr.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const modifiedCart = cart?.map((productDetails) => {
    const { quantity, productId, sku_attr } = productDetails;
    return {
      logistics_service_name: "CAINIAO_FULFILLMENT_STD",
      sku_attr: sku_attr,
      product_count: quantity,
      product_id: productId,
    };
  });

  const modifiedCartItems = cartItems?.cart?.map((obj) => {
    const { productId, quantity, product } = obj;
    return {
      productID: productId,
      sellerId: product?.userId,
      cost: cartItems?.cartPrice,
      quantityOrdered: quantity?.toString(),
      productName: product?.productName,
      productDescription: product?.productDescription,
      countryOfOrigin: product?.countryOfOrigin || "Nigeria",
      logisticsStatus: logisticsStatus === "successful" ? "PAID" : "UNPAID",
    };
  });

  //invoice for aliexpress
  const aliexpressInvoice = cart?.map((productDetails) => {
    return {
      name: productDetails?.productName,
      image: productDetails?.imgUrl,
      quantity: productDetails?.quantity,
      price: productDetails?.cost,
    };
  });

  const tofaInvoice = cartItems?.cart?.map((productDetails) => {
    return {
      name: productDetails?.product?.productName,
      image: productDetails?.product?.productImages[0]?.image,
      quantity: productDetails?.quantity,
      price: productDetails?.totalPrice,
    };
  });

  const getLocationDetails = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );

      const data = await response.json();

      if (data.status !== "OK") {
        toast.error(
          `Invalid address. Please enter a valid address from the suggestions.`,
          toastOptions
        );
        return;
      }

      const addressComponents = data.results[0].address_components;
      const locationDetails = {
        streetNo: "",
        home: "",
        street: "",
        city: "",
        state: "",
        country: "",
        countyShort: "",
        zipCode: "",
      };

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          locationDetails.streetNo = component.long_name;
        }
        if (types.includes("route")) {
          locationDetails.home = component.long_name;
        }
        if (types.includes("neighborhood")) {
          locationDetails.street = component.long_name;
        }
        if (types.includes("administrative_area_level_2")) {
          locationDetails.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          locationDetails.state = component.long_name;
        }
        if (types.includes("country")) {
          locationDetails.country = component.long_name;
        }
        if (types.includes("country")) {
          locationDetails.countyShort = component.short_name;
        }
        if (types.includes("postal_code")) {
          locationDetails.zipCode = component.long_name;
        }
      });

      return locationDetails;
    } catch (error) {
      toast.error(
        `Invalid address. Please enter a valid address from the suggestions.`,
        toastOptions
      );
    }
  };

  const handleSubmit = async (address) => {
    if (totalCartQuantity < 1 && cart.length < 1) {
      return;
    }

    if (!address) {
      toast.error(`Please enter an address.`, toastOptions);
      return;
    }

    await getLocationDetails(address);

    const error = errors.find((error) => error.condition);
    if (totalCartQuantity > 0 && error) {
      toast.error(error.message, toastOptions);
      return;
    }

    let promises = [];

    setLoading(true);
    if (totalCartQuantity > 0) {
      const tofaOrderPayload = {
        bulkOrder: modifiedCartItems,
        destination: user?.address,
        port:
          userDetails.incoterm === "LOCAL_DELIVERY" ? "None" : userDetails.port,
        note: "None",
        incoterm: userDetails.incoterm,
        shippingType: userDetails.shippingType,
        paymentTerm: paymentTerm,
      };

      const tofaPromise = axiosInstance.post(
        "/order/bulk-order",
        tofaOrderPayload
      );

      promises.push(tofaPromise);
    }

    if (cart.length > 0) {
      const locationDetails = await getLocationDetails(address);

      const { streetNo, home, street, city, state, countyShort, zipCode } =
        locationDetails;

      const { countryCode, mainNumber } = separateCountryCode(
        user?.phoneNumber
      );

      const aliexpressOrderPayload = {
        dsExtendRequest: {
          payment: {
            pay_currency: "NGN",
          },
        },
        paramPlaceOrderRequest4OpenAPIDTO: {
          product_items: modifiedCart,
          logistics_address: {
            full_name: `${user?.firstName + " " + user?.LastName}`,
            contact_person: `${user?.firstName + " " + user?.LastName}`,
            address: `${streetNo + " " + home + " " + street}`,
            address2: user?.address,
            city: city,
            province: `${state + " " + "state"}`,
            country: countyShort,
            zip: zipCode,
            phone_country: countryCode,
            mobile_no: mainNumber,
            locale: "en_US",
          },
        },
      };

      const aliexpressPromise = axiosInstance.post(
        "/ali-express/order/create",
        aliexpressOrderPayload
      );
      promises.push(aliexpressPromise);
    }

    try {
      const [data, data1] = await Promise.all(promises);
      if (
        totalCartQuantity > 0 &&
        cart.length > 0 &&
        data1?.data?.aliexpress_ds_order_create_response?.result?.is_success ===
          false
      ) {
        toast.error(
          data1?.data?.aliexpress_ds_order_create_response.result.error_msg,
          toastOptions
        );
        return;
      } else if (
        totalCartQuantity < 1 &&
        cart.length > 0 &&
        data?.data?.aliexpress_ds_order_create_response?.result?.is_success ===
          false
      ) {
        toast.error(
          data?.data?.aliexpress_ds_order_create_response.result.error_msg,
          toastOptions
        );
        return;
      }
      if (totalCartQuantity > 0 && data) {
        localStorage.setItem(
          "orderIds",
          JSON.stringify(data.data.data.orderIDs)
        );
      }

      await toast.success(
        "Your order has been recorded. Kindly make payment to complete order.",
        toastOptions
      );

      // const aliexpressOrderId = () => {
      //   return data?.aliexpress_ds_order_create_response?.result;
      // };

      const invoiceDetails = {
        userEmail: user?.email,
        userPhoneNumber: user?.phoneNumber,
        userName: `${user?.firstName} ${user?.LastName}`,
        userAddress: user?.email,
        grandTotal: numberWithCommas(grandTotal()),
        orderDetails: {
          orderId: "",
          products: aliexpressInvoice,
          totalAmount: numberWithCommas(totalCost),
        },
        orderDetails2: {
          products2: tofaInvoice,
          totalAmount2: numberWithCommas(removeCommasAndConvert()),
        },
      };

      handleSendInvoice(invoiceDetails);

      const checkIfBothAndPaymentEqualPayNow =
        data && data1 && paymentTerm === "PAYNOW";
      const checkIfItsJustAliexpressProduct =
        totalCartQuantity < 1 && cart.length > 0 && data;

      const JustTofasProductAndPaymentEqualsPaynow =
        totalCartQuantity > 0 &&
        cart.length < 1 &&
        data &&
        paymentTerm === "PAYNOW";

      if (
        checkIfBothAndPaymentEqualPayNow ||
        checkIfItsJustAliexpressProduct ||
        JustTofasProductAndPaymentEqualsPaynow
      ) {
        handleFlutterPayment({
          callback: (response) => {
            const transactionId = response.tx_ref.toString();
            const totalAmountUSD = grandTotal;
            const userId = user.id;

            const savedOrderIds = localStorage.getItem("orderIds");
            const OrderIds = JSON.parse(savedOrderIds);

            if (OrderIds) {
              updateOrderStatus(OrderIds);
            }
            handleEventTag("pay_successful", user.id);
            localStorage.removeItem("cart");
            handleDelete(cartItems.cart);

            axiosInstance
              .post("/payment", {
                transactionId,
                userId,
                totalAmountUSD,
              })
              .then((response) => {
                navigate("/order/success-message");
              })
              .catch((error) => {
                console.error(error);
                navigate("/order/success-message");
                toast.error(`${error.response.data.errors[0].message}`, {
                  duration: 4000,
                  style: {
                    background: "#353434",
                    color: "#fff",
                  },
                });
              });
          },
          onClose: () => {
            console.log("Payment canceled or closed.");
          },
        });
      } else {
        setTimeout(() => {
          handleDelete(cartItems.cart);
          navigate(`/order/bank-payment/${cartItems?.cartPrice}`);
        }, 2000);
      }
    } catch (error) {
      console.log("error placing order", error);

      if (error?.aliexpress_ds_order_create_response?.result?.error_msg) {
        toast.error(
          error.aliexpress_ds_order_create_response.result.error_msg,
          toastOptions
        );
        handleEventTag(`e_ali_checkout_${error}`, user.id);
      } else if (error?.response?.data?.errors[0].message) {
        toast.error(error?.response?.data?.errors[0].message, toastOptions);
        handleEventTag(
          `e_checkout_${error.response.data.errors[0].message}`,
          user.id
        );
      } else {
        handleEventTag(`e_checkout`, user.id);
        toast.error(`Network error!`, toastOptions);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
    setSelectedCurrenci,
    paymentTerm,
    setPaymentTerm,
    setUserDetails,
    userDetails,
    currentSelectedCurrency,
    selectedCurrenci,
    totalCartQuantity,
    grandTotal,
    totalCost,
  };
};
