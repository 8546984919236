import React, { useEffect } from "react";

const PaymentFeedBack = ({ toggleModal, setToggleModal }) => {
  const redirectPage = () => {
    const redirectTimer = setTimeout(() => {
      setToggleModal(!toggleModal);
    }, 5000);
    return () => {
      clearTimeout(redirectTimer);
    };
  };

  useEffect(() => {
    redirectPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="modal">
      <div className="modal_container payment_feedback_wrapper">
        <div className="payment_feedback md:shadow-shadow1">
          <>
            <img
              src="https://static.vecteezy.com/system/resources/previews/002/743/514/non_2x/green-check-mark-icon-in-a-circle-free-vector.jpg"
              alt="success"
            />
            <h1>Delivery Created Sccessfully</h1>
            <p>Your delivery booked. You will be redirected shortly...</p>
          </>
        </div>
      </div>
    </div>
  );
};

export default PaymentFeedBack;
