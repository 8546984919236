import { useState } from "react";
import { axiosInstance } from "../baseUrl";
import cookies from "js-cookie";

export const useGetAliexpressProductsByCategoryName = () => {
  const [products, setProducts] = useState([]);
  const [loadAli, setLoadAli] = useState(false);

  const selectedCurrency = cookies.get("currency") || "NGN";

  const getAliexpressCategories = async (feedName) => {
    setLoadAli(true);
    try {
      const data = await axiosInstance.post(`/ali-express/feed-name`, {
        country: "NG",
        target_currency: selectedCurrency === "NGN" ? "NGN" : "USD",
        target_language: "EN",
        page_size: "100",
        sort: "priceAsc",
        page_no: "1",
        category_id: " ",
        feed_name: feedName,
      });

      setProducts(
        data?.data?.aliexpress_ds_recommend_feed_get_response?.result?.products
          ?.traffic_product_d_t_o
      );
      setLoadAli(false);
    } catch (error) {
      console.log("ali-express/feed-name error", error);
      setLoadAli(false);
    }
  };
  return {
    getAliexpressCategories,
    loadAli,
    products,
  };
};
