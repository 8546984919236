import React from "react";
import { numberWithCommas } from "../../components/helpers/functionHelpers";

const AliexpressCartItems = ({ cart, removeFromCart, addToCart }) => {
  return (
    <>
      {" "}
      {cart && cart.length > 0 && (
        <div className="col-12">
          <div className="over-card">
            <h2 className="user__title">Products from Aliexpress</h2>
            <div className="user-product-ctn">
              {cart?.map((product, index) => (
                <div className="user-product-wrap" key={index}>
                  <div className="user-product-details">
                    <div className="user-product-img">
                      <img src={product?.imgUrl} alt="product" />
                    </div>
                  </div>
                  <div className="user-product-name">
                    <div className="product-name">
                      <p>{product?.productName}</p>
                    </div>
                    <div className="product-amount">
                      <p>
                        {product?.currency}{" "}
                        {`${numberWithCommas(
                          product?.cost * product?.quantity
                        )}`}
                      </p>

                      <div className="product-count-aliexpress">
                        <button
                          className="negative"
                          onClick={() => removeFromCart(product?.productId)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <h2 className="quanity">{product?.quantity}</h2>
                        <button
                          className="positive"
                          onClick={() => addToCart(product)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AliexpressCartItems;
