import React, { useContext } from "react";
import { Link } from "react-router-dom";
// import { TofaButton } from "tofa-ok-package";

import { useTranslation } from "react-i18next";
import TofaButton from "../../components/TofaButton/TofaButton.js";

import { GlobalContext } from "../../components/utils/GlobalState.js";
import {
  addTwoPercent,
  calculateTwoPercent,
  numberWithCommas,
} from "../../components/helpers/functionHelpers.js";

const CartPaymentSummary = ({
  cartItems,
  loader,
  handleSubmit,
  currentSelectedCurrency,
  convertPrice,
  options,
  cart,
  address,
  grandTotal,
  totalCost,
}) => {
  const { t } = useTranslation();
  const { totalCartQuantity } = useContext(GlobalContext);

  return (
    <>
      {totalCartQuantity > 0 && cart.length > 0 ? (
        <div className="col-md-4 col-12">
          <div className="over-card">
            <div className="cart-ctn">
              <h5>{t("Invoice Summary")}</h5>

              <>
                <div className="cart-cost">
                  <h6>Delivered by Aliexpress</h6>
                  <div className="cart-wrap">
                    <p>{t("Total_item_cost")}</p>
                    <p>
                      {cart[0]?.currency}{" "}
                      {totalCost
                        ? numberWithCommas(totalCost.toFixed(2))
                        : "0.00"}
                    </p>
                  </div>
                  <div className="cart-wrap">
                    <p>Handling fee (2%)</p>
                    <p>
                      {cart[0]?.currency}{" "}
                      {numberWithCommas(calculateTwoPercent(totalCost))}
                    </p>
                  </div>
                  <div className="cart-wrap">
                    <p>Free delivery</p>
                  </div>
                </div>
                <div className="total-cost">
                  <p>{t("Total")}</p>
                  <h6>
                    {cart[0]?.currency}{" "}
                    {totalCost
                      ? numberWithCommas(addTwoPercent(totalCost))
                      : "0.00"}
                  </h6>
                </div>
              </>

              <>
                <div className="cart-cost">
                  <h6>Delivered by TOFA</h6>
                  <div className="cart-wrap">
                    <p>{t("Total_item_cost")}</p>
                    <p>
                      {currentSelectedCurrency}{" "}
                      {cartItems.cartPrice
                        ? convertPrice(
                            cartItems.cartPrice,
                            currentSelectedCurrency
                          )
                        : "0.00"}
                    </p>
                  </div>
                  <div className="logistic-ctn">
                    <p>{t("Need_logistics")}?</p>
                    <TofaButton option={options} />
                  </div>
                </div>
                <div className="total-cost">
                  <p>{t("Total")}</p>
                  <h6>
                    {currentSelectedCurrency}{" "}
                    {cartItems?.cartPrice
                      ? convertPrice(
                          cartItems.cartPrice,
                          currentSelectedCurrency
                        )
                      : "0.00"}
                  </h6>
                </div>
              </>
              <div className="grand__total">
                <h5>Grand total</h5>
                <h6>
                  {currentSelectedCurrency} {numberWithCommas(grandTotal())}
                </h6>
              </div>
              <div className="pb-2">
                .
                {loader ? (
                  <button>
                    {" "}
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button onClick={() => handleSubmit(address)}>
                    {t("Place order")}
                  </button>
                )}
              </div>

              <p className="terms">
                {t("Upon_clicking_Place_order")}{" "}
                <Link to="/terms-of-use"> {t("Terms_Conditions")} </Link>{" "}
                {t("and")} <Link to="/privacy"> {t("Privacy_Policy")}.</Link>
              </p>
            </div>
          </div>
        </div>
      ) : totalCartQuantity < 1 && cart.length > 0 ? (
        <div className="col-md-4 col-12">
          <div className="over-card">
            <div className="cart-ctn">
              <h5>{t("Summary")}</h5>

              <div className="cart-cost">
                <h6>Delivered by Aliexpress</h6>
                <div className="cart-wrap">
                  <p>{t("Total_item_cost")}</p>
                  <p>
                    {cart[0]?.currency}{" "}
                    {totalCost
                      ? numberWithCommas(totalCost.toFixed(2))
                      : "0.00"}
                  </p>
                </div>
                <div className="cart-wrap">
                  <p>Handling fee (2%)</p>
                  <p>
                    {cart[0]?.currency}{" "}
                    {numberWithCommas(calculateTwoPercent(totalCost))}
                  </p>
                </div>
                <div className="cart-wrap">
                  <p>Free delivery</p>
                </div>
              </div>
              <div className="total-cost">
                <p>{t("Total")}</p>
                <h6>
                  {cart[0]?.currency} {numberWithCommas(grandTotal())}
                </h6>
              </div>

              <div className=" pb-2">
                .
                {loader ? (
                  <button>
                    {" "}
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button onClick={() => handleSubmit(address)}>
                    {t("Place order")}
                  </button>
                )}
              </div>

              <p className="terms">
                {t("Upon_clicking_Place_order")}{" "}
                <Link to="/terms-of-use"> {t("Terms_Conditions")} </Link>{" "}
                {t("and")} <Link to="/privacy"> {t("Privacy_Policy")}.</Link>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-md-4 col-12">
          <div className="over-card">
            <div className="cart-ctn">
              <h5>{t("Summary")}</h5>
              <div className="cart-cost">
                <h6>Delivered by TOFA</h6>
                <div className="cart-wrap">
                  <p>{t("Total_item_cost")}</p>
                  <p>
                    {currentSelectedCurrency}{" "}
                    {cartItems.cartPrice
                      ? convertPrice(
                          cartItems.cartPrice,
                          currentSelectedCurrency
                        )
                      : "0.00"}
                  </p>
                </div>
                <div className="cart-wrap">
                  <p>VAT</p>
                  <p>{currentSelectedCurrency} 0.00</p>
                </div>
              </div>
              <div className="total-cost">
                <p>{t("Total")}</p>
                <h6>
                  {currentSelectedCurrency} {numberWithCommas(grandTotal())}
                </h6>
              </div>

              <div className="logistic-ctn">
                <p>{t("Need_logistics")}?</p>
                <TofaButton option={options} />
              </div>

              {loader ? (
                <button>
                  {" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button onClick={() => handleSubmit(address)}>
                  {t("Place order")}
                </button>
              )}
              <p className="terms">
                {t("Upon_clicking_Place_order")}{" "}
                <Link to="/terms-of-use"> {t("Terms_Conditions")} </Link>{" "}
                {t("and")} <Link to="/privacy"> {t("Privacy_Policy")}.</Link>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartPaymentSummary;
